.ConnectToProject {
  .ant-typography {
    margin-bottom: 0;
    margin-right: 0.4rem;
    // This should only be show on 1 line
    white-space: nowrap;
  }
  .ant-select-focused {
    // border-color: #ffccd8 !important;
    .ant-select-selector {
      border-color: #ffccd8 !important;
    }
  }
  .ant-select-selector:hover {
    border-color: #ffccd8 !important;
  }
}
